<template>
  <div
    class="dp-flex flex-d-c flex-1 h-0"
    v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0.6)"
  >
    <handle-btn
      :selectedRowScopes="selectedRowScopes"
      :level="level"
      :isShowSetBidAmount="isShowSetBidAmount"
      :params="params"
      @reload="getList"
      @setShowOpenClock="setShowOpenClock"
      @editbidAmount="editbidAmount"
      @getCurrentColumn="getCurrentColumn"
    ></handle-btn>
    <div class="flex-1 dp-flex flex-d-c h-0">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        height="calc(100% - 90px)"
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
        ref="table"
      >
        <el-table-column
          type="selection"
          width="55"
        >
        </el-table-column>
        <el-table-column
          prop="ad.campaignName"
          :label="nameLabel"
          width="320"
          fixed
        >
          <template slot-scope="scope">
            <ads-name
              :msg="scope.row"
              :level="level"
              :index="scope.$index"
              edit
              @setMsg="setMsg"
              @copy="copy"
              @log="log"
              @delete="deleteItem"
              @getChildren="getAdset"
              @todayTrend="todayTrend"
              @handelEdit="handelEdit"
            ></ads-name>
          </template>
        </el-table-column>
        <el-table-column
          v-for="column in currentColumn.columns ? currentColumn.columns[level] : []"
          :key="column.id"
          :label="column.filedName"
          :min-width="column.width ? column.width + 'px' : '105px'"
          :sortable="['spend', 'order_count'].includes(column.filedKey) ? 'custom' : false"
          :prop="column.filedKey"
        >
        <template slot="header">
          <span>
            <span>{{ column.filedName }}</span>
            <el-tooltip>
              <span slot='content'>订单:有效订单<br />付费:总订单</span>
              <i class="el-icon-question" v-show="column.filedKey == 'order_count'"></i>
            </el-tooltip> 
          </span>
        </template>

          <!-- 状态 -->
          <template slot-scope="{ row }">
            <template v-if="column.filedKey == 'adSecondStatus'">
              <div>
                <div class="dp-flex align-item-c">
                  <el-tooltip
                    effect="light"
                    content="有拒登"
                    placement="bottom"
                    v-if="
                      (level == 'campaign' && row.ad.disapprovedList && row.ad.disapprovedList.length) ||
                      (level == 'adset' &&
                        adCampaignMsg.disapprovedList &&
                        adCampaignMsg.disapprovedList.find((item) => item.adGroupId == row.ad.adGroupId))
                    "
                  >
                    <span class="el-icon-warning-outline text-danger mr-5"></span>
                  </el-tooltip>
                  <status :type="normalStatus.includes(row.ad.adSecondStatus) ? 'success' : 'error'">
                    {{ row.ad.adSecondStatusName }}
                  </status>
                </div>

                <div v-if="row.ad.aiSuggestion">
                  <aiSuggestion :aiSuggestion="row.ad.aiSuggestion"></aiSuggestion>
                </div>
              </div>
            </template>
            <!-- 预算 -->
            <template v-if="column.filedKey == 'budget'">
              <budget
                :msg="row"
                :level="level"
                @editClick="editBudget"
              ></budget>
            </template>
            <!-- 出价 -->
            <template v-if="column.filedKey == 'bidAmount'">
              <div v-if="row.ad.bidModelName">
                <div v-if="row.ad.bidAmount">
                  {{ row.ad.bidHasCurrency ? row.statistics.currencySymbol : '' }}{{ row.ad.bidAmount }}
                  <i
                    class="el-icon-edit"
                    style="color: #409eff"
                    v-if="!(row.ad.platform == 'google' && row.ad.objectTive == 'VIDEO')"
                    @click="editbidAmount(row)"
                  ></i>
                </div>
                <div>{{ row.ad.bidModelName }}</div>
                <el-button
                  type="primary"
                  icon="el-icon-alarm-clock"
                  size="mini"
                  plain
                  round
                  v-show="row.ad.bidAmount && row.ad.platform == 'facebook'"
                  @click="setBidAmountTime(row)"
                  >定时改出价
                </el-button>
              </div>
            </template>
            <!-- 经营分析 -->
            <template v-if="column.filedKey == 'productReport'">
              <adCellProductReport
                :adProductReport="row.productReport"
                :level="level"
                :campaignId="row.ad.campaignId"
                :adAccountId="row.ad.adAccountId"
                :currency_sign="row.statistics.currency"
                :platform="row.ad.platform"
                :dateRange="[params.startDate, params.endDate]"
              ></adCellProductReport>
            </template>
            <!-- 毛利率(%) -->
            <template v-if="column.filedKey == 'profitRatePre'">
              <div v-if="row.productReport && row.productReport.profitRatePre">
                {{ row.productReport.profitRatePre }}%
              </div>
            </template>
            <!-- 花费 -->
            <template v-if="column.filedKey == 'spend'">
              <span>{{ row.statistics.currencySymbol }}{{ row.spend && row.spend.toFixed(2) }}</span>
              <div
                style="color: #c0c4cc"
                v-if="row.ad.latestUpdateTime"
              >
                {{ row.ad.latestUpdateTime.slice(11, 16) }}
              </div>
            </template>
            <!-- 订单 -->
            <template v-if="column.filedKey == 'order_count'">
              <!-- TTS -->
              <localOrder
                :level="level"
                :id="row.ad.adGroupId || row.ad.campaignId"
                :row="row"
                :params="{
                  dateStart: params.startDate,
                  dateEnd: params.endDate,
                }"
                :api="getLocalOrderStatApi"
                :platform="platform"
              >
                <div>
                  <div v-if="row.ad.objectiveEnum == 'PRODUCT_SALES'">
                    {{ row.statistics.onsiteShopping }}/{{ row.statistics.costPerOnsiteShopping }}
                  </div>
                  <div v-else>
                    <div>{{ row.statistics.orderCount }}/{{ row.statistics.totalOrderCount }}</div>
                    <div>
                      成本：{{ row.statistics.perOrderCost && row.statistics.perOrderCost.toFixed(2) }}/{{
                        row.statistics.perTotalOrderCost && row.statistics.perTotalOrderCost.toFixed(2)
                      }}
                    </div>
                  </div>
                </div>
              </localOrder>
            </template>
            <!-- 总收入 -->
            <template v-if="column.filedKey == 'totalOnsiteShoppingValue'">{{
              row.statistics.totalOnsiteShoppingValue
            }}</template>
            <template v-if="column.filedKey == 'addPayCount'">
              <div>
                <div v-if="row.ad.objectiveEnum == 'PRODUCT_SALES'">
                  {{ row.statistics.onsiteInitiateCheckoutCount }}/{{
                    row.statistics.costPerOnsiteInitiateCheckoutCount
                  }}
                </div>
                <div v-else>
                  <div v-if="row.ad.platform == 'line'">{{ row.statistics.cv }}/{{ row.statistics.cvr }}</div>
                  <div v-else>
                    {{ row.statistics.addPayCount }}/{{
                      row.statistics.perAdPayCost && row.statistics.perAdPayCost.toFixed(2)
                    }}
                  </div>
                </div>
              </div>
            </template>
            <!-- 点击 -->
            <template v-if="column.filedKey == 'click'">
              <span>{{ row.statistics.click || 0 }}/{{ row.statistics.perClickCost || '--' }}</span>
            </template>
            <!-- dopay -->
            <template v-if="column.filedKey == 'dopay'">
              <span>{{ row.statistics.dopay }}/{{ row.statistics.finalCost }}</span>
            </template>
            <!-- 转化率 -->
            <template v-if="column.filedKey == 'onsiteShoppingRate'">
              <div v-if="row.statistics.onsiteShoppingRate">{{ row.statistics.onsiteShoppingRate }}%</div>
            </template>
            <!-- 标签 -->
            <template v-if="column.filedKey == 'adLabelInfos'">
              <div v-if="row.ad.adLabelInfos">
                <el-tag
                  type="info"
                  v-for="adLabelInfo in row.ad.adLabelInfos"
                  :key="adLabelInfo.labelId"
                  size="mini"
                  >{{ adLabelInfo.name }}</el-tag
                >
              </div>
            </template>
            <!-- 广告账户 -->
            <template v-if="column.filedKey == 'adAccountName'">
              <account :data="row.ad"></account>
            </template>
            <!-- 平台 -->
            <template v-if="column.filedKey == 'platform'">
              <div>{{ row.ad.objectiveEnum == 'PRODUCT_SALES' ? 'tiktok shop' : row.ad.platform }}</div>
            </template>
            <!-- 展示次数 -->
            <template v-if="column.filedKey == 'impressions'">
              <div>{{ row.statistics.impressions }}</div>
            </template>
            <!-- 点击率  -->

            <template v-if="column.filedKey == 'ctr'">
              <div>{{ row.statistics.ctr }}%</div>
            </template>
            <!-- 千次展示费用  cpm -->
            <template v-if="column.filedKey == 'cpm'">
              <div>{{ row.statistics.cpm }}</div>
            </template>
            <!--   链接点击率 inlineLinkClickCtr -->
            <template v-if="column.filedKey == 'inlineLinkClickCtr' && row.statistics.inlineLinkClickCtr">
              <div>{{ row.statistics.inlineLinkClickCtr }}</div>
            </template>
            <!--   链接点击成本 costPerInlineLinkClick -->
            <template v-if="column.filedKey == 'costPerInlineLinkClick' && row.statistics.costPerInlineLinkClick">
              <div>{{ row.statistics.costPerInlineLinkClick }}</div>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop="ad.open_schedule"
          width="260"
          label="定时操作"
          fixed="right"
          v-if="level != 'ad'"
        >
          <template slot-scope="scope">
            <div class="flex items-center">
              <div v-if="scope.row.ad.hasSchedule==1||(scope.row.ad.open_schedule && scope.row.ad.open_schedule.length > 0)">
                <img
                  src="@/assets/image/jiqiren.png"
                  style='width:16px;height:16px'
                ></img>
              </div>
              <div class='flex felx-col'>
              <div
                v-if="scope.row.ad.open_schedule && scope.row.ad.open_schedule.length > 0"
                :key="tableKey"
              >
                <p
                  v-for="(item, index) in scope.row.ad.open_schedule"
                  :key="scope.row.id + index"
                  class="open-schedule"
                >
                  {{ item.timeStr }} {{ clockType[item.type] }}
                  <el-button
                    type="text"
                    size="mini"
                    @click="cancelSet({ item, index, row: scope.row })"
                    >取消</el-button
                  ><el-button
                    type="text"
                    size="mini"
                    @click.stop="
                      setShowOpenClock({
                        type: item.type,
                        data: item.type == 2 || item.type == 3 ? scope.row : [scope.row],
                        status: item.type == 1 ? true : false,
                        defaultData: item.timeStr,
                      })
                    "
                    >编辑</el-button
                  >
                </p>
              </div>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div
        class="totalShow"
        v-loading="totalLoading || platformLoading"
        element-loading-background="rgba(0, 0, 0, 0.6)"
      >
        <div class="h100 dp-flex align-item-c">
          <span class="ml-20">总计</span>
          <span class="ml-20">当日花费：￥{{ totalData.spend && totalData.spend.toFixed(2) }}</span>
          <span class="ml-20">订单量：{{ totalData.orderCount }}</span>
          <span class="ml-20">毛利率：{{ ((totalData.grossMarginRate || 0) * 100).toFixed(2) }}%</span>
        </div>
        <div class="h100 dp-flex align-item-c ml-20">
          <span class="ml-20">{{ platform }}</span>
          <span class="ml-20">当日花费：￥{{ totalDataByPlatform.spend && totalDataByPlatform.spend.toFixed(2) }}</span>
          <span class="ml-20">订单量：{{ totalDataByPlatform.orderCount }}</span>
          <span class="ml-20">毛利率：{{ ((totalDataByPlatform.grossMarginRate || 0) * 100).toFixed(2) }}%</span>
          <span class="ml-20">更新时间：{{ totalData.lastUpTime }}</span>
          <i
            class="el-icon-refresh-left resh ml-10"
            @click="getTotalByPlatform"
          ></i>
        </div>
      </div>
      <div
        style="margin-top: 10px; text-align: right"
        class="dp-flex justify-btw align-item-c"
      >
        <div style="font-size: 14px; color: #666">
          <span v-if="level == 'campaign'">当日有花费的系列数：{{ totalDataByPlatform.spendCampaignCount }}</span>
        </div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="pageSizes[platform] || [20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotal"
        >
        </el-pagination>
      </div>
    </div>
    <spend-dialog ref="spendDialog" />
    <copy
      :show.sync="showCopy"
      :data="editdata"
      :level="level"
    ></copy>
    <editSnap :show.sync="showEdit"  :editData="editdata" :level="level" @getList="getList"/>
    <logDraw
      :show.sync="showLog"
      needSign
      :apiParams="{
        platform: platform,
        level: level,
        campaignId: editdata.ad ? editdata.ad.campaignId : null,
        adGroupId: editdata.ad ? editdata.ad.adGroupId : null,
        adId: editdata.ad ? editdata.ad.adId : null,
      }"
    ></logDraw>
    <!-- 当日趋势 -->
    <todayTrend
      :show.sync="showTodayTrend"
      :data="editdata"
      :level="level"
    ></todayTrend>
    <!-- 预算 -->
    <budgetEdit
      :show.sync="showBudget"
      :data="editdata"
      :level="level"
      :isClock="isClock"
    ></budgetEdit>
    <!-- 出价 -->
    <editBidAmount
      v-if="showBidAmount"
      :show.sync="showBidAmount"
      :data="editdata"
      :level="level"
    ></editBidAmount>

    <!-- 定时开启 -->
    <openClock
      :show.sync="showOpenClock"
      :level="level"
      :ids="editdata"
      :status="statusOpen"
      :defaultData="defaultDataOpenClock"
    ></openClock>
    <!-- 定时改出价 -->
    <setTimeEditBid
      ref="setTimeEditBid"
      :level="level"
      :row="{
        id: editdata.ad ? editdata.ad.adGroupId : null,
        bid_strategy: editdata.ad ? editdata.ad.bidModel : null,
        bid_amount: editdata.ad ? editdata.ad.bidAmount : null,
        open_schedule: editdata.ad ? editdata.ad.open_schedule : null,
        adAccountId: editdata.ad ? editdata.ad.adAccountId : null,
        campaignId: editdata.ad ? editdata.ad.campaignId:  null,
        adGroupId:  editdata.ad ? editdata.ad.adGroupId:  null,
        adGroupAdId: editdata.ad ? editdata.ad.adId:  null,
      }"
      @settingComplete="setBidModelSuccess"
    ></setTimeEditBid>
  </div>
</template>

<script>
import handleBtn from './handleBtn.vue';
import status from '@/components/common/status.vue';
import copy from './copy.vue';
import editSnap from './editSnap/index.vue';
import { logDraw, localOrder, adCellProductReport } from '@adCommonComponent';
import { ProductReport } from '@adCommonComponent/utils/ProductReport.js';
import {
  campaignList,
  delAdScheduleOpenTaskMobile,
  delAdScheduleUpdateBudgetTaskMobile,
  delUpdateBidTask,
  mutateStatus,
  getLocalOrderStat,
  dailyStatisticsByPlatform,
} from '@/api/multiPlatform';
import adsName from './adsName.vue';
import budget from './budget.vue';
import orderTongji from './orderTongji.vue';
import spendDialog from './spendDialog.vue';
import todayTrend from './todayTrend.vue';
import budgetEdit from './budgetEdit.vue';
import editBidAmount from './editBidAmount.vue';
import openClock from './openClock.vue';
import aiSuggestion from './aiSuggestion.vue';
import setTimeEditBid from '@/views/adManagement/components/table/setTimeEditBid.vue';
import account from './account.vue';
const clockType = {
  1: '定时开',
  2: '定时预算',
  3: '定时出价',
  4: '定时关',
};
const normalStatus = [
  'VALUE_ACTIVE',
  'VALUE_IN_PROCESS',
  'ELIGIBLE',
  'LEARNING',
  'ENABLED',
  'SERVING',
  'CAMPAIGN_STATUS_ENABLE',
  'ADGROUP_STATUS_DELIVERY_OK',
  'AD_STATUS_DELIVERY_OK',
  'VALID',
  'ACTIVE',
];
export default {
  name: 'adCampaign',
  props: {
    totalData: {
      type: Object,
      default: () => ({}),
    },
    totalLoading: Boolean,
    level: {
      type: String,
      default: 'campaign',
    },
    params: {
      type: Object,
      default: () => {},
    },
    adMsg: Object,
    adCampaignMsg: Object,
    AdScheduleOpenTaskType: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    budget,
    adsName,
    orderTongji,
    spendDialog,
    handleBtn,
    adCellProductReport,
    status,
    copy,
    logDraw,
    todayTrend,
    budgetEdit,
    editBidAmount,
    openClock,
    aiSuggestion,
    localOrder,
    setTimeEditBid,
    account,
    editSnap,
  },
  data() {
    return {
      tableData: [],
      tableKey: '',
      currentPage: 1,
      pageSize: 20,
      pageTotal: 0,
      orderReset: false,
      selectedRowScopes: [], //选中数据
      isShowSetBidAmount: false,
      orderBy: this.level == 'campaign' ? 'spend' : null, // 排序名称
      ascOrDesc: this.level == 'campaign' ? 'DESC' : null, // 排序规则 DESC降序 ASC升序
      editdata: {}, // 编辑数据
      showCopy: false, // 显示复制弹框
      showEdit: false, // 显示编辑弹框
      showLog: false, // 显示日志
      showTodayTrend: false, // 当日趋势
      showBudget: false, // 预算
      showBidAmount: false, // 出价
      clockType,
      showOpenClock: false, // 显示定时
      statusOpen: false, //定时开关状态 ture开 false关
      defaultDataOpenClock: null,
      isClock: false, // 定时操作
      loading: true, // 加载中
      platform: null,
      normalStatus,
      pageSizes: {
        tiktok: [20, 30, 40],
        snapchat: [20, 30, 40],
      },
      localOrderStat: {},
      openLoaclId: null,
      scrollTop: {},
      tableHtml: null,
      currentColumn: {}, // 当前列配置
      totalDataByPlatform: {}, // 当前平台的统计数据
      platformLoading: false,
      getLocalOrderStatApi: getLocalOrderStat,
    };
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
    userId() {
      return this.$store.state.user.userInfo.data.id;
    },
    nameLabel() {
      const label = {
        campaign: '系列名称',
        adset: '广告组名称',
        ad: '广告名称',
      };
      return label[this.level];
    },
  },
  methods: {
    // 定制列
    getCurrentColumn(currentColumn) {
      this.currentColumn = currentColumn;
      this.$nextTick(() => {
        this.$refs.table.doLayout();
      });
    },
    getIncreaseBidAmount(amount) {
      return (amount > 0 ? '增加 ' : '减少 ') + Math.abs(amount);
    },
    // 取消定时
    cancelSet({ item, index, row }) {
      let request = null;
      if (item.type == 2) {
        // 预算定时
        request = delAdScheduleUpdateBudgetTaskMobile;
      } else if (item.type == 3) {
        request = delUpdateBidTask;
      } else {
        request = delAdScheduleOpenTaskMobile;
      }

      this.$confirm('确定取消定时任务', '提示', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let data = {
            taskId: item.id,
            id: item.id,
          };

          request(data).then((res) => {
            this.$message.success('取消成功！');
            row.ad.open_schedule.splice(index, 1);
            this.$set(row.ad, 'hasSchedule', false)
          });
        })
        .catch(() => {});
    },
    // 监听选择表格的选择项
    handleSelectionChange(v) {
      this.selectedRowScopes = v;
    },
    lookSpendChat(row) {
      this.$refs.spendDialog.show(row.ad);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    sortChange({ column, prop, order }) {
      this.orderBy = prop;
      let ascOrDesc = {
        ascending: 'ASC',
        descending: 'DESC',
      };
      this.ascOrDesc = ascOrDesc[order] || null; // 排序规则 DESC降序 ASC升序
      this.currentPage = 1;
      this.getList();
    },
    getList() {
      let params = {
        currency: null,
        // sort: 'SPEND',
        level: this.level,
        gkPage: {
          //分页信息。可以为空。空时使用默认值。
          index: this.currentPage,
          size: this.pageSize,
        },
        ascOrDesc: this.ascOrDesc,
        orderBy: this.orderBy,
        disapproved: this.level == 'campaign' ? true : false,
      };

      if (this.level === 'adset') {
        params.ad = {
          adAccountId: this.adCampaignMsg.adAccountId,
          campaignId: this.adCampaignMsg.campaignId,
          campaignName: this.adCampaignMsg.campaignName,
          platform: this.params.platform,
        };
      }
      if (this.level == 'ad') {
        params.ad = {
          adAccountId: this.adMsg.adAccountId,
          campaignId: this.adMsg.campaignId,
          campaignName: this.adMsg.campaignName,
          platform: this.params.platform,
          adGroupId: this.adMsg.adGroupId,
          adGroupName: this.adMsg.adGroupName,
        };
      }
      this.orderReset = false;
      this.loading = true;
      this.platform = this.params.platform;
      this.localOrderStat = {};
      console.log('---------------', this.params, JSON.stringify(this.params))
      this.getTotalByPlatform(); // 获取当前平台的统计数据
      campaignList({ ...params, ...this.params })
        .then((res) => {
          let data = res.data.adReportList;
          data.forEach((item) => {
            const tts = item.objectiveEnum == 'PRODUCT_SALES';
              if (tts && item.productReport) {
                item.productReport.orderCount = item.statistics.realTimeConversion;
              }
              if (this.level == 'adset' || this.level == 'ad') {
                item.ad.objectiveEnum = this.adCampaignMsg.objectiveEnum;
                
                // 获取经营分析
                if (this.level == 'adset' && this.adCampaignMsg.productReport) {
                  let purchase =
                    this.adCampaignMsg.objectiveEnum == 'PRODUCT_SALES'
                      ? item.statistics.realTimeConversion
                      : item.statistics.orderCount;
                  let spend = item.statistics.spend * this.adCampaignMsg.productReport.currencyRate;
                  if (Number(purchase) && this.adCampaignMsg.productReport) {
                    item.productReport = new ProductReport(this.adCampaignMsg.productReport, purchase, spend);
                  }
                }
                //tt Smart+ 只在系列和广告组上有是否是smart标识字段，广告上没有，所以需要把广告组层级的给广告层级赋值
                if(this.level == 'ad'){
                  item.ad.smartPerformanceCampaign = this.adMsg?.smartPerformanceCampaign
                }
            }
          });
          this.tableData = data;

          this.pageTotal = res.data.gkPage.total;
          this.orderReset = true;
          this.$nextTick(() => {
            this.$refs.table.clearSort();
            const table = this.$refs.table.$el.querySelectorAll('.el-table__body-wrapper')[0];
            table.scrollTop = 0;
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadMore() {
      this.currentPage += 1;
      this.getList();
    },
    setMsg(index, key, val) {
      let msg = this.tableData[index];
      msg.ad[key] = val;
      this.$set(this.tableData, index, msg);
      this.$forceUpdate();
    },
    getAdset(row) {
      const table = this.$refs.table.$el.querySelectorAll('.el-table__body-wrapper')[0];
      this.tableHtml = table;
      this.scrollTop[this.level] = table.scrollTop;
      this.$emit('setLevelMsg', {
        ...row.ad,
        level: this.level === 'campaign' ? 'adset' : 'ad',
        spend: row.statistics.spend,
        statistics: { ...row.statistics },
        productReport: row.productReport?{...row.productReport}: null,
      });
    },

    reload() {
      this.currentPage = 1;
      this.tableData = [];
      this.isShowSetBidAmount = this.params.platform == 'twitter';
      this.getList();
    },
    // 复制
    copy(row) {
      this.editdata = row;
      this.showCopy = true;
    },
    // 编辑
    handelEdit(row) {
      this.editdata = row;
      this.showEdit = true;
      console.log(row,this.showEdit,'this.showEdit');
    },
    // 日志
    log(row) {
      this.editdata = row;
      this.showLog = true;
    },
    todayTrend(row) {
      this.editdata = row;
      this.showTodayTrend = true;
    },
    deleteItem(row) {
      let params = {
        platform: row.ad.platform,
        campaignId: row.ad.campaignId,
        adsetId: row.ad.adGroupId,
        adId: row.ad.adId,
        accountId: row.ad.adAccountId,
        status: 'DELETED',
        level: this.level,
        objectiveEnum: row.ad.objectiveEnum,
        clientType: 'pc',
        functionType: 'manual',
      };
      this.$showLoading();
      mutateStatus(params).then((res) => {
        this.$hideLoading();
        this.$message.success('删除成功');
        this.reload();
      });
    },
    editBudget(row, clock) {
      this.editdata = row;
      this.showBudget = true;
      this.isClock = clock;
    },
    editbidAmount(row) {
      this.editdata = row;
      this.showBidAmount = true;
    },
    // status 如果是定时状态修改，区别是开启还是关闭
    setShowOpenClock({ type, data, status, defaultData }) {
      this.editdata = data;
      switch (type) {
        case 1:
        case 4:
          this.statusOpen = status;
          this.defaultDataOpenClock = defaultData;
          this.showOpenClock = true;
          break;
        case 2:
          this.isClock = true;
          this.showBudget = true;
          break;
        case 3:
          this.editdata = data;
          this.$refs.setTimeEditBid.show();
          break;
      }
    },
    getLocalOrderStat(data) {
      let id = this.level == 'campaign' ? data.ad.campaignId : data.ad.adGroupId;
      if (this.localOrderStat[id] || this.localOrderStat[id] === null) {
        return;
      }
      let params = {
        actId: data.ad.adAccountId,
        dateStart: this.params.startDate,
        dateEnd: this.params.endDate,
        platform: this.platform,
        level: this.level,
        daysDiff: 7, // 统计时间天数，近7天传7  近15天传15
        ids: [id], //广告id  如果广告层级是系列传系列id，如果是组传组id
      };
      getLocalOrderStat(params).then((res) => {
        this.localOrderStat = {
          ...this.localOrderStat,
          ...res.data,
        };
      });
    },
    setBidAmountTime(row) {
      this.editdata = row;
      this.$nextTick(() => {
        this.$refs.setTimeEditBid.show();
      });
    },
    setBidModelSuccess(formData, type, groudId, bidAmount, id, res) {
      let oldOPenSchedule = (this.editdata.ad.open_schedule || []).filter((item) => item.type != 3);
      const newOpenSchedule = [res, ...oldOPenSchedule];
      this.$set(this.editdata.ad, 'open_schedule', [...newOpenSchedule]);
    },
    getTotalByPlatform() {
      this.$emit('getTotalData');
      const params = {
        platform: this.params.platform,
      };
      this.platformLoading = true;
      dailyStatisticsByPlatform(params)
        .then((res) => {
          this.totalDataByPlatform = res.data;
        })
        .finally(() => {
          this.platformLoading = false;
        });
    },
  },
  mounted() {
    this.getList();
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.table.doLayout();
      setTimeout(() => {
        const table = this.$refs.table.$el.querySelectorAll('.el-table__body-wrapper')[0];
        table.scrollTop = this.scrollTop[this.level];
      }, 100);
    });
  },
  watch: {
    adMsg() {
      if (this.level == 'ad') {
        this.getList();
      }
    },
    adCampaignMsg() {
      if (this.level == 'adset') {
        this.orderBy = null; // 排序名称
        this.ascOrDesc = null;
        this.$refs.table.clearSort();
        this.getList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ml-100 {
  margin-left: 100px;
}
.loadMore {
  text-align: center;
  line-height: 28px;
  color: #999;
}
.open-schedule {
  font-size: 12px;
  text-align: left;
  span {
    display: block;
  }

  .set-open {
    color: #409eff;
    margin-bottom: 5px;
  }
  .budget-span {
    color: #999;
  }
  .el-button {
    color: #409eff;
  }
}
.totalShow {
  background: #409eff;
  color: #fff;
  padding: 0 24px;
  border-radius: 8px;
  font-size: 14px;
  height: 40px;
  display: flex;
  align-items: center;
}
.resh {
  cursor: pointer;
}
</style>
